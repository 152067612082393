const LandingPages = () => {
    // getRepeatedCharCounts('aaccddddqqvrrds')
    const getRepeatedCharCounts = (input) => {
        const arr = new Set(input)

        var result = '';
        for (var i of arr) {
            result += i + ((input.split(i).length) - 1)
        }
        return result
    }

    return (
        <h1>Landing Pages</h1>
    )
}
export default LandingPages